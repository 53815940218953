import React, { useContext } from 'react';
import { graphql, PageProps } from 'gatsby';
import ServiceDetail from '@/components/Service/ServiceDetail';
import { Context } from '@/components/Layout';

const ServicePage: React.FC<PageProps<GatsbyTypes.ServicePageQuery>> = ({
  data,
}) => {
  const loading = useContext(Context);
  return !loading && <ServiceDetail data={data} />;
};

export default ServicePage;

export const query = graphql`
  query ServicePage($ServicePageId: String!) {
    microcmsService(serviceId: { ne: "template", eq: $ServicePageId }) {
      id
      body {
        anchorId
        class
        column {
          fieldId
          rich
          plain
        }
        contact {
          button
          display
          tayori
          title
          id
        }
        file {
          url
        }
        asset {
          assetBody {
            plain
            rich
          }
        }
        fileLabel
        rich
        plain
      }
      contact {
        button
        tayori
        title
        id
      }
      label
      category
      serviceId
      title
      subTitle
      description
      keyword
      publishedAt(formatString: "YYYY/MM/DD")
      tag {
        id
        tag
      }
    }
  }
`;
